<!--走进园区-->
<template>
  <div class="servewrap">

    <!--顶部-->
    <headbox></headbox>

    <!-- 滑块 -->
    <banner positionSid="1776"></banner>

    <!--  单页面-->
    <Singlepage :titleEnglish="this.$store.state.project.titleEnglishIsShow" bjImgHidden="1"></Singlepage>


    <!-- 侧边导航 -->
    <sidebar ref="mysiderbar"></sidebar>

    <!--底部-->
    <foot></foot>

  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import banner from "@/components/banner";
import headbox from "@/components/head";
import foot from "@/components/foot";
import Singlepage from "@/components/Singlepage";
import sidebar from "@/components/sidebar";
import wow from "wowjs";

export default {
  components: {
    Singlepage,
    banner,
    headbox,
    foot,
    sidebar,
  },
  data() {
    return {
      mySwiper: {},
      swiper: {
        imgs: [],
      },
      tabbar_active: 0,
      yqjs: {
        title:"公司介绍",
        text: "",
        img: "",
      },
      yqln: { //   公司理念
        title:"公司理念",
        text: "            国外SNS社交平台Facebook、YouTube、\n" +
            "            Twitter、Linkedln、Tumb《等全方位运营，专业内容出身，并基于战略伙伴·中国网\n" +
            "            \"等多个官媒平台和自有各类国内自媒体号，深得社交平台精髓！\n" +
            "            深入融合和依吒浙商组织，数十年聚焦\"浙商\n" +
            "            \"积累经验，已成为\"浙商\"地标性的服务机构。提出的“大道至简，实干为要。共赢共享，通舟共济”的发展观，充分发挥政策红利和时域优势，致力于调动市场中的经济发展势能，汇集杭州，促进杭州经济\n" +
            "            发展突破地域限制，实现建设“主动引导型”跨境产业的目标，打造成为中国第一跨境产业集群。",
        list: [],
      },
      yyms: {
        content: {
          pattern_Name: "管理模式以及运营模式",
          pattern_Content: `<div class="glmsrow">管理架构</div>
                                  <div class="glmsrow">盼云跨境（中国杭州）电子商务产业园</div>
                                  <div class="glmsrow">运营管理单位：浙江盼云跨境运营管理有限公司</div>
                                  <div class="glmsrow">管理架构</div>
                                  <div class="glmsrow">政府主导+名企引领+创业者为主体</div>`,
        },
      },
    };
  },

  methods: {
    hideChat(e) {
      this.$refs.mysiderbar.hideChat();
    },
    yqtabbar(index) {
      this.tabbar_active = index;
    },
  },
  beforeMount() {
    let projectid = this.$store.state.project.projectid;
    if (this.$store.state.project.servedata) {
      // yqjsapi({ apartment_Sid: projectid }).then((res) => {
      //   this.yqjs.title= res.data.result[0].about_Name
      //   this.yqjs.text = res.data.result[0].about_Content;
      //   this.yqjs.img = staticurl + res.data.result[0].about_Logo;
      // });
      // yqlnapi({ apartment_Sid: projectid }).then((res) => {
      //   this.yqln.title=res.tag.notion_Main_Name    // 公司理念
      //   this.yqln.text=res.tag.notion_Main_Content  // 公司理念 富文本
      //   this.yqln.list = res.data.result.map((item, index) => {
      //     item.notion_Logo = staticurl + item.notion_Logo;
      //     return item;
      //   });
      // });
      // yymsapi({ apartment_Sid : projectid }).then((res) => {
      //   // this.yyms.content = res.data.result[0];
      // })
      // swiperapi({ apartment_Sid: projectid, position_Sid: "2" }).then((res) => {
      //   this.swiper.imgs = res.data.result.map((item) => {
      //     item.imgurl = staticurl + item.picture;
      //     return item;
      //   });
      // });
    } else {
      //   this.swiper.imgs = [{ imgurl: "./assets/images/yqlnswiper1.jpg" }];
      //   this.yqjs.text = `<div class="yqjstext1">
      //                    盼云跨境电子商务产业园，是一家以抖音小店店主、抖音主播为服务对象的综合电子商务产业园，为抖音百万商家及主播提供包括工商注册、财税服务、知识产权、供应链等一站式配套服务，免费提供注册地址，入园享受多项福利政策。
      //                 </div>
      //                 <div class="yqjstext1">
      //                   盼云跨境核心成员曾供职于央企、阿里巴巴，为多家主流电商平台提供过成功的行业解决方案，园区设税务规划、税务服务、风险管控的专业团队，成员均深耕财税行业5年以上，具备注册会计师、注册税务师资格。
      //                 </div>
      //                 <div class="yqjstext1">
      //                   盼云跨境以“登记注册在线化、市场主体多样化、线上线下一体化、园区管理智能化”为核心，借助庞大的商家主播群体，全方位打造一个集电商、直播、供应链于一体的新零售产业服务平台，以点带面，实现直播电商产业服务升级。盼云跨境旗下“抖小号”作为其移动端产品，集品宣、一键入园、服务选购、客服咨询四位一体，为广大入园商家提供更便捷、更全面的运营指南。
      //                 </div>`;
      //
      //   this.yqjs.img = "./assets/images/us.jpg";
      //   this.yqln.list = [
      //     {
      //       notion_Logo: "./assets/images/yqln1.png",
      //       notion_Name: "成本低",
      //       notion_Content:
      //         "无需大量资金囤一键代发,无需美工客服,一人轻松打理店铺",
      //     },
      //     {
      //       notion_Logo: "./assets/images/yqln2.png",
      //       notion_Name: "门槛低",
      //       notion_Content:
      //         " 无需外语，不限基础，赠送店铺系统,一键铺货，一键上传，小白也能轻松上手",
      //     },
      //     {
      //       notion_Logo: "./assets/images/yqln3.png",
      //       notion_Name: "空间大",
      //       notion_Content: "平台产品单价高，客户消费能力强，产品利润可观",
      //     },
      //     {
      //       notion_Logo: "./assets/images/yqln4.png",
      //       notion_Name: "行业预期好",
      //       notion_Content: "跨境电商将在，未来很长一段时间内,保持高速增长",
      //     },
      //   ];
    }
  },
  mounted() {
    this.mySwiper = new Swiper(".swiper-container", {
      // initialSlide: 0,
      // direction: 'vertical',
      // effect : 'coverflow',
      mousewheelControl: true,
      allowSlidePrev: false,
      allowSlideNext: false,
      autoplay: {
        delay: 2000,
      },
      on: {
        init: function (swiper) {
          // console.log(swiper)
          // let slide = swiper.slides[0].classList;
          // console.log(slide)
          // slide.add('ani-slide');
        },
        transitionStart: function (swiper) {
          // for(let i = 0; i < swiper.slides.length; i++){
          //   	let slide = swiper.slides[i].classList;
          //     slide.remove('ani-slide');
          // }
        },
        transitionEnd: function (swiper) {
          // console.log(swiper)
          // let slide = swiper.slides[swiper.activeIndex].classList;
          // slide.add('ani-slide');
        },
      },
    });
    this.$nextTick(() => {
      new wow.WOW().init();
    });

    // 页面增加全局点击
    window.addEventListener("click", this.hideChat);
  },
  beforeDestroy() {
    /* 组件销毁的时候要移除侦听器 */
    window.removeEventListener("click", this.hideChat);
  },
};
</script>

<style scoped>
.servewrap {
  font-size: 12px;
}
</style>
